var App, Resizer,
  bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

Resizer = (function() {
  Resizer.prototype.breakpoints = {
    xs: 0,
    sm: 768,
    md: 992,
    lg: 1200
  };

  Resizer.prototype.target = null;

  Resizer.prototype.timer = null;

  function Resizer(target) {
    if (target == null) {
      target = '.adaptive-bg';
    }
    this.onResize = bind(this.onResize, this);
    this.target = target;
    jQuery(window).on('resize', (function(_this) {
      return function(e) {
        e.preventDefault();
        clearTimeout(_this.timer);
        return _this.timer = setTimeout(_this.onResize, 100);
      };
    })(this));
    jQuery(window).on('scroll', (function(_this) {
      return function(e) {
        e.preventDefault();
        clearTimeout(_this.timer);
        return _this.timer = setTimeout(_this.onResize, 50);
      };
    })(this));
    this.onResize();
  }

  Resizer.prototype.onResize = function() {
    var $window, radius, self, size, viewport;
    $window = jQuery(window);
    size = $window.width();
    radius = 0.5;
    viewport = {
      width: $window.width(),
      height: $window.height(),
      top: $window.scrollTop(),
      bottom: $window.scrollTop() + $window.height(),
      left: $window.scrollLeft(),
      right: $window.scrollLeft() + $window.width()
    };
    viewport.top += radius * viewport.height / 2;
    viewport.bottom += radius * viewport.height / 2;
    viewport.left += radius * viewport.width / 2;
    viewport.right += radius * viewport.width / 2;
    self = this;
    return jQuery(this.target).each(function(i, e) {
      var $fakeImg, $this, bounds, isImg, key, src, visibility;
      $this = jQuery(this);
      bounds = $this.offset();
      bounds.right = bounds.left + $this.outerWidth();
      bounds.bottom = bounds.top + $this.outerHeight();
      visibility = {
        x: false,
        y: false
      };
      isImg = jQuery(this).is('img');
      if (isImg) {
        return;
      }
      visibility.y = (bounds.top >= viewport.top && bounds.top <= viewport.bottom) || (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom) || (bounds.top <= viewport.top && bounds.bottom >= viewport.bottom);
      if (!visibility.y) {
        return;
      }
      src = null;
      for (key in self.breakpoints) {
        if (self.breakpoints[key] <= size && ($this.attr('data-src-' + key) != null)) {
          src = $this.attr('data-src-' + key);
        }
      }
      if (src != null) {
        return $fakeImg = jQuery('<img />').attr('src', src).load(function() {
          return $this.css('background-image', 'url(' + src + ')');
        });
      }
    });
  };

  return Resizer;

})();

window.Resizer = Resizer;

App = (function() {
  App.prototype.resizer = null;

  function App(conf) {
    var $discover, $discoverbottle, $inOnFilters, $recipes, self;
    self = this;
    jQuery('footer.content-info a.btn-top').on('click', function(e) {
      e.preventDefault();
      return jQuery('body, html').animate({
        scrollTop: 0
      }, 'fast');
    });
    this.resizer = new Resizer();
    jQuery('body > header > .container a.btn-menu').on('click', function(e) {
      e.preventDefault();
      return jQuery('body > header').toggleClass('open');
    });
    if (jQuery('body').is('.page-template-template-homepage')) {
      $discover = jQuery('main.main .discover');
      $discoverbottle = jQuery('main.main .discover-bottle');
      $discover.find('.discover-content > ul > li > a').on('mouseover', function(e) {
        e.preventDefault();
        $discover.css('background-color', jQuery(this).next().attr('data-color'));
        return $discoverbottle.css('background-image', 'url(' + jQuery(this).next().attr('data-src-md') + ')');
      });
      $discover.find('.discover-content > ul > li:first-child > a').trigger('mouseover');
    }
    jQuery('body.page-template-template-landing .landing-facts .facts-header a.btn-toggle').on('click', function(e) {
      e.preventDefault();
      return jQuery(this).parent().parent().toggleClass('open');
    });
    $recipes = jQuery('.section-recipes');
    $inOnFilters = $recipes.find('>.recipes-inon');
    if (($inOnFilters != null) && ($inOnFilters.length != null)) {
      $inOnFilters.find('ul > li > a[data-tag]').on('click', function(e) {
        var tags;
        e.preventDefault();
        e.stopPropagation();
        jQuery(this).toggleClass('active');
        if (jQuery(this).is('.active')) {
          jQuery(this).parent().parent().find('>li>a.active').not(jQuery(this)).removeClass('active');
        } else {
          jQuery(this).parent().parent().find('>li>a').not(jQuery(this)).addClass('active');
        }
        if (jQuery(this).parent().parent().find('>li>a.active').length === 0) {
          jQuery(this).parent().parent().find('>li>a').addClass('active');
        }
        jQuery(this).parent().parent().find('>li').removeClass('active').each(function(i, e) {
          if (jQuery(this).find('>a.active').length > 0) {
            return jQuery(this).addClass('active');
          }
        });
        tags = [];
        jQuery(this).parent().parent().find('>li>a.active').each(function(i, e) {
          return tags.push(jQuery(this).attr('data-tag'));
        });
        $recipes.find('ul.recipes-list').fadeTo('fast', 0.0002, function() {
          $recipes.find('ul.recipes-list > li').each(function(i, e) {
            var j, len, matches, tag;
            matches = tags.length === 0;
            for (j = 0, len = tags.length; j < len; j++) {
              tag = tags[j];
              matches = matches || jQuery(this).is('.tag-' + tag) || jQuery(this).is('.tag-' + tag + '-fr');
              if (matches) {
                break;
              }
            }
            if (matches) {
              return jQuery(this).show();
            } else {
              return jQuery(this).hide();
            }
          });
          return $recipes.find('ul.recipes-list').fadeTo('fast', 1);
        });
        return false;
      });
      $inOnFilters.find('ul > li > a[data-tag]').first().trigger('click');
      $inOnFilters.find('ul > li > a[data-tag]').each(function() {
        return jQuery(this).parent().on('click', function(e) {
          e.preventDefault();
          e.stopPropagation();
          jQuery(this).find('>a[data-tag]').trigger('click');
          return false;
        });
      });
    }
  }

  return App;

})();

jQuery.fn.attachDragger = function() {
  var attachment, difference, lastPosition, position, selector;
  attachment = false;
  lastPosition = void 0;
  position = void 0;
  difference = void 0;
  selector = jQuery(this).selector;
  jQuery(jQuery(this).selector).on('mousedown mouseup mousemove', function(e) {
    if (e.type === 'mousedown') {
      attachment = true;
      lastPosition = [e.clientX, e.clientY];
      jQuery(this).addClass('grabbing');
    }
    if (e.type === 'mouseup') {
      attachment = false;
      jQuery(this).removeClass('grabbing');
    }
    if (e.type === 'mousemove' && attachment === true) {
      position = [e.clientX, e.clientY];
      difference = [position[0] - lastPosition[0], position[1] - lastPosition[1]];
      jQuery(this).scrollLeft(jQuery(this).scrollLeft() - difference[0]);
      jQuery(this).scrollTop(jQuery(this).scrollTop() - difference[1]);
      lastPosition = [e.clientX, e.clientY];
    }
  });
  jQuery(window).on('mouseup', function() {
    attachment = false;
    jQuery(selector).removeClass('grabbing');
  });
};

jQuery(function() {
  jQuery('[data-toggle="tooltip"]').tooltip();
});

jQuery('body :not(script)').contents().filter(function() {
  return this.nodeType === 3;
}).replaceWith(function() {
  return this.nodeValue.replace(/[™®©]/g, '<sup>$&</sup>');
});

jQuery(function() {
  window.app = new App();
  jQuery('.landing-facts > ul.facts').attachDragger();
  window.sr = ScrollReveal({
    reset: true
  });
  sr.reveal('div.section-recipes > h3, div.section-recipes > a');
  sr.reveal('div.section-recipes > h3, div.section-recipes > ul > li', {}, 50);
  sr.reveal('div.landing-section p');
  return sr.reveal('div.sauce-page .sauce-primary, div.sauce-page .sauce-secondary', {}, 120);
});
